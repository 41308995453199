<template>
  <!-- player -->
  <PodcastPlayer :audio-path="audioPath" v-if="this.showPlayer" ref="audioPlayer"/>
  <!-- main content -->
  <main class="main">
    <div class="container">
      <div class="row row--grid">
        <!-- title -->
        <div class="col-12">
          <div class="main__title main__title--page">
            <h1> {{ albumTitle }}</h1>
          </div>
        </div>
        <!-- end title -->

        <div class="col-12">
          <div class="release">
            <div class="release__content">
              <div v-if="isLoading">
                <div style="width:280px; height: 300px; background: grey; border-radius: 8px"/>
              </div>
              <div v-else class="release__cover" style="height: 300px; width: 300px">
                <img :src="imageUrl(albumImage, true)" alt="image"
                     style="height: 300px; width: 300px; object-fit: cover">
              </div>
              <div v-if="isLoading">
                <div style="width:160px; height: 30px; background: grey; border-radius: 8px; margin-top: 10px"/>
              </div>
              <div v-else class="release__stat">
                <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path
                    d="M21.65,2.24a1,1,0,0,0-.8-.23l-13,2A1,1,0,0,0,7,5V15.35A3.45,3.45,0,0,0,5.5,15,3.5,3.5,0,1,0,9,18.5V10.86L20,9.17v4.18A3.45,3.45,0,0,0,18.5,13,3.5,3.5,0,1,0,22,16.5V3A1,1,0,0,0,21.65,2.24ZM5.5,20A1.5,1.5,0,1,1,7,18.5,1.5,1.5,0,0,1,5.5,20Zm13-2A1.5,1.5,0,1,1,20,16.5,1.5,1.5,0,0,1,18.5,18ZM20,7.14,9,8.83v-3L20,4.17Z"/></svg> {{
                    podcastCount
                  }} подкаст</span>
              </div>
            </div>
            <div v-if="isLoading" style="display: flex; flex-direction: column">
              <div style="display: flex; flex-direction: row">
                <div style="width:50px; height: 50px; background: grey; border-radius: 8px; margin-top: 10px"/>
                <div style="display: flex; flex-direction: column">
                  <div
                      style="width:400px; height: 20px; background: grey; border-radius: 8px; margin-top: 10px; margin-left: 10px"/>
                  <div
                      style="width:400px; height: 20px; background: grey; border-radius: 8px; margin-top: 10px; margin-left: 10px"/>
                </div>

              </div>

              <div style="display: flex; flex-direction: row; margin-top: 20px">
                <div style="width:50px; height: 50px; background: grey; border-radius: 8px; margin-top: 10px"/>
                <div style="display: flex; flex-direction: column">
                  <div
                      style="width:400px; height: 20px; background: grey; border-radius: 8px; margin-top: 10px; margin-left: 10px"/>
                  <div
                      style="width:400px; height: 20px; background: grey; border-radius: 8px; margin-top: 10px; margin-left: 10px"/>
                </div>

              </div>

              <div style="display: flex; flex-direction: row; margin-top: 20px">
                <div style="width:50px; height: 50px; background: grey; border-radius: 8px; margin-top: 10px"/>
                <div style="display: flex; flex-direction: column">
                  <div
                      style="width:400px; height: 20px; background: grey; border-radius: 8px; margin-top: 10px; margin-left: 10px"/>
                  <div
                      style="width:400px; height: 20px; background: grey; border-radius: 8px; margin-top: 10px; margin-left: 10px"/>
                </div>

              </div>
            </div>
            <div v-else style="width: 100%; margin-left: 20px">
              <div class="release__list" v-if="podcastList.length > 0">
                <ul class="main__list main__list--playlist main__list--dashbox" v-for="(item, index) in podcastList"
                    :key="index"
                >
                  <li class="single-item" @click="playAudio( item.audio, item.image, item.title, item.creator, index)">
                    <a data-playlist data-title="1. Got What I Got" data-artist="Jason Aldean"
                       data-img="/img/covers/cover.svg"
                       class="single-item__cover"
                       :class="{'single-item__cover active play': item.audio === checkAudioPath}"
                    >
                      <img :src="imageUrl(item.image, true)" alt="image" style="height: 50px; width: 50px">

                      <svg v-if=" item.audio === checkAudioPath && isPlaying"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M16,2a3,3,0,0,0-3,3V19a3,3,0,0,0,6,0V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-2,0V5a1,1,0,0,1,2,0ZM8,2A3,3,0,0,0,5,5V19a3,3,0,0,0,6,0V5A3,3,0,0,0,8,2ZM9,19a1,1,0,0,1-2,0V5A1,1,0,0,1,9,5Z"/>
                      </svg>

                      <!--                    playing false icon    -->

                      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z"/>
                      </svg>
                    </a>
                    <div class="single-item__title">
                      <h4>{{ index + 1 }}. {{ item.title }}</h4>
                      <span>{{ item.creator }}</span>
                    </div>
                  </li>
                  <span style="width: 100%;   border-bottom: 2px solid #e6eaf2;"/>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="article" style="width: 100%">
          <!-- article description -->
          <div class="article__content" style="width: 100%">
            <div v-html="podAlbumDescription" style="width: 100%"></div>
          </div>
          <!--end article description -->
        </div>

      </div>
    </div>
  </main>
  <!-- end main content -->
</template>
<script>
import PodcastPlayer from "../../../components/podcast/PodcastPlayer.vue";
import {mapState, mapActions} from 'vuex';
import {imageUrl} from "@utils";
import api from "../../../store/_boot/baseApi";

export default {
  components: {PodcastPlayer},

  data() {
    return {
      albumTitle: null,
      podcastList: [],
      otherPodcastList: [],
      albumImage: null,
      podcastCount: null,
      podAlbumDescription: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      audioPath: (state) => state.audioPath,
      checkAudioPath: (state) => state.checkAudioPath,
      audioTitle: (state) => state.audioTitle,
      audioCreator: (state) => state.audioCreator,
      audioImage: (state) => state.audioImage,
      isPlaying: (state) => state.isPlaying,
      showPlayer: (state) => state.showPlayer,
    }),
    // eslint-disable-next-line vue/no-dupe-keys
    isLoading() {
      return this.isLoading;
    },
  },

  methods: {
    ...mapActions([
      'setAudioPath',
      'setCheckAudioPath',
      'setAudioTitle',
      'setAudioCreator',
      'setAudioImage',
      'setIsPlaying',
      'play',
      'pause',
      'setVolume',
      'setShowPlayer',
      'setPlayList',
      'setCurrentIndex'
    ]),

    imageUrl,
    playAudio(path, image, title, creator, index) {
      this.setAudioPath(path);
      this.setCheckAudioPath(path);
      this.setAudioImage(image);
      this.setAudioTitle(title);
      this.setAudioCreator(creator);
      this.setShowPlayer(true);
      this.setPlayList(this.podcastList);
      this.setCurrentIndex(index);
      // this.audioPath = path;

      this.$nextTick(() => {
        if (this.$refs.audioPlayer) {
          this.$refs.audioPlayer.currentTime = 0;
          this.$refs.audioPlayer.playAudio();
        } else {
          console.error("audioPlayer ref is not defined.");
        }
      });
    },
    podcast(album_id) {
      this.isLoading = true;
      api.get('api/web/podcast', {
        params: {'pod_album_id': album_id}
      })
          .then((data) => {
            this.albumImage = data.data.data.image;
            this.albumTitle = data.data.data.title;
            this.podcastList = data.data.data.podcast;
            this.otherPodcastList = data.data.data.other_podcast;
            this.podcastCount = data.data.data.podcast_count;
            this.podAlbumDescription = data.data.data.description;
            this.isLoading = false;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            this.isLoading = false;
          });
    },
  },

  created() {
    this.podcast(this.$route.params.id);
  },
};
</script>