<template>
  <!-- player -->

  <div class="player">
    <div class="container">
      <button type="button" @click="closePlayer" style=" position: absolute; right: 18px; top: 18px">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="fs-24">
          <path d="M18 6l-12 12"></path>
          <path d="M6 6l12 12"></path>
        </svg>
      </button>

      <div class="player__cover" v-if="this.fullImagePath">
        <img :src="this.fullImagePath" alt="cover">
        <div class="p-info">
          <h5>{{ audioTitle }}</h5>
          <span>{{ audioCreator }}</span>
        </div>
      </div>

      <div class="player__cover" v-else>
        <img src="/img/covers/cover.svg" alt="">
      </div>

      <div class="player__content">

        <div class="plyr__actions">
          <button type="button" class="plyr__control plyr__control--prev" @click="previousTrack">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                  d="M20.28,3.43a3.23,3.23,0,0,0-3.29,0L8,8.84V6A3,3,0,0,0,2,6V18a3,3,0,0,0,6,0V15.16l9,5.37a3.28,3.28,0,0,0,1.68.47,3.24,3.24,0,0,0,1.61-.43,3.38,3.38,0,0,0,1.72-3V6.42A3.38,3.38,0,0,0,20.28,3.43ZM6,18a1,1,0,0,1-2,0V6A1,1,0,0,1,6,6Zm14-.42a1.4,1.4,0,0,1-.71,1.25,1.23,1.23,0,0,1-1.28,0L8.68,13.23a1.45,1.45,0,0,1,0-2.46L18,5.19A1.23,1.23,0,0,1,18.67,5a1.29,1.29,0,0,1,.62.17A1.4,1.4,0,0,1,20,6.42Z"></path>
            </svg>
          </button>

          <button type="button" class="plyr__control plyr__control--pressed main_btn" data-plyr="play"
                  @click="togglePlayPause"
                  aria-label="Pause">
            <svg v-if="isPlaying" class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24">
              <path
                  d="M16,2a3,3,0,0,0-3,3V19a3,3,0,0,0,6,0V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-2,0V5a1,1,0,0,1,2,0ZM8,2A3,3,0,0,0,5,5V19a3,3,0,0,0,6,0V5A3,3,0,0,0,8,2ZM9,19a1,1,0,0,1-2,0V5A1,1,0,0,1,9,5Z"></path>
            </svg>
            <svg v-else class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24">
              <path
                  d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z"></path>
            </svg>
          </button>

          <button type="button" class="plyr__control plyr__control--next" @click="nextTrack">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                  d="M19,3a3,3,0,0,0-3,3V8.84L7,3.47a3.21,3.21,0,0,0-3.29,0A3.38,3.38,0,0,0,2,6.42V17.58a3.38,3.38,0,0,0,1.72,3A3.24,3.24,0,0,0,5.33,21,3.28,3.28,0,0,0,7,20.53l9-5.37V18a3,3,0,0,0,6,0V6A3,3,0,0,0,19,3ZM15.32,13.23,6,18.81a1.23,1.23,0,0,1-1.28,0A1.4,1.4,0,0,1,4,17.58V6.42a1.4,1.4,0,0,1,.71-1.25A1.29,1.29,0,0,1,5.33,5,1.23,1.23,0,0,1,6,5.19l9.33,5.58a1.45,1.45,0,0,1,0,2.46ZM20,18a1,1,0,0,1-2,0V6a1,1,0,0,1,2,0Z"></path>
            </svg>
          </button>
        </div>

        <div class="plyr__wrap">

          <div class="plyr__progress">
            <input type="range"
                   class="progress_bar"
                   min="0"
                   :max="duration"
                   :value="currentTime"
                   @input="seek($event)"
                   aria-label="Seek"/>
          </div>

          <div class="plyr__time">
            <span class="plyr__time--current">{{ formatTime(currentTime) }}</span>/
            <div class="plyr__time--duration">{{ formatTime(duration) }}</div>
          </div>

          <button
              type="button"
              class="plyr__control"
              :class="{ 'plyr__control--pressed': isMuted }"
              aria-label="Mute"
              @click="toggleMute"
          >
            <svg v-if="isMuted" class="icon--pressed" role="presentation" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24">
              <path
                  d="M12.43,4.1a1,1,0,0,0-1,.12L6.65,8H3A1,1,0,0,0,2,9v6a1,1,0,0,0,1,1H6.65l4.73,3.78A1,1,0,0,0,12,20a.91.91,0,0,0,.43-.1A1,1,0,0,0,13,19V5A1,1,0,0,0,12.43,4.1ZM11,16.92l-3.38-2.7A1,1,0,0,0,7,14H4V10H7a1,1,0,0,0,.62-.22L11,7.08ZM19.91,12l1.8-1.79a1,1,0,0,0-1.42-1.42l-1.79,1.8-1.79-1.8a1,1,0,0,0-1.42,1.42L17.09,12l-1.8,1.79a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l1.79-1.8,1.79,1.8a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>

            </svg>
            <svg v-else class="icon--not-pressed" role="presentation" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24">
              <path
                  d="M12.43,4.1a1,1,0,0,0-1,.12L6.65,8H3A1,1,0,0,0,2,9v6a1,1,0,0,0,1,1H6.65l4.73,3.78A1,1,0,0,0,12,20a.91.91,0,0,0,.43-.1A1,1,0,0,0,13,19V5A1,1,0,0,0,12.43,4.1ZM11,16.92l-3.38-2.7A1,1,0,0,0,7,14H4V10H7a1,1,0,0,0,.62-.22L11,7.08ZM19.66,6.34a1,1,0,0,0-1.42,1.42,6,6,0,0,1-.38,8.84,1,1,0,0,0,.64,1.76,1,1,0,0,0,.64-.23,8,8,0,0,0,.52-11.79ZM16.83,9.17a1,1,0,1,0-1.42,1.42A2,2,0,0,1,16,12a2,2,0,0,1-.71,1.53,1,1,0,0,0-.13,1.41,1,1,0,0,0,1.41.12A4,4,0,0,0,18,12,4.06,4.06,0,0,0,16.83,9.17Z"></path>
            </svg>
          </button>
        </div>

        <audio
            style="display: none"
            ref="audioElement"
            :src="audioPath"
            @timeupdate="onTimeUpdate"
            @play="play"
            @pause="pause"
            @error="onAudioError"
            @ended="onTrackEnded"
            controls
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex';

export default {
  props: ['audioPath'],

  data() {
    return {
      currentTime: 0,
      duration: 0,
      isMuted: false,
      baseUrl: 'https://www.bloomberg.nnc.mn',
    };
  },
  computed: {
    ...mapGetters(['isPlaying', 'playlist']),
    ...mapState({
      audioTitle: (state) => state.audioTitle,
      audioCreator: (state) => state.audioCreator,
      audioImage: (state) => state.audioImage,
      isPlaying: (state) => state.isPlaying,
      volume: (state) => state.volume,
      baseUrl: (state) => state.baseUrl,
      currentTrackIndex: (state) => state.currentTrackIndex,
    }),

    fullAudioPath() {
      return `${this.baseUrl}${this.audioPath}`;
    },
    fullImagePath() {
      return `${this.baseUrl}${this.audioImage}`;
    }
  },

  methods: {
    ...mapActions([
      'play',
      'pause',
      'setAudioPath',
      'setIsPlaying',
      'setPlayList',
      'setCurrentIndex',
      'setVolume',
      'setShowPlayer',
    ]),

    playAudio() {
      if (this.$refs.audioElement) {
        this.$refs.audioElement.src = this.fullAudioPath;
        this.$refs.audioElement.currentTime = this.currentTime;
        this.$refs.audioElement.play().catch(error => {
          console.error('Error playing audio:', error);
        });
        this.play();
      }
    },

    pauseAudio() {
      if (this.$refs.audioElement) {
        this.currentTime = this.$refs.audioElement.currentTime;
        this.$refs.audioElement.pause();
        this.pause(); // Ensure this interacts with Vuex correctly
      }
    },

    togglePlayPause() {
      if (this.isPlaying) {
        this.pauseAudio();
      } else {
        this.playAudio();
      }
    },

    previousTrack() {
      console.log('playList length', this.playlist.length)
      const newIndex = this.currentTrackIndex - 1;
      console.log('playIndex', newIndex)

      if (newIndex < this.playlist.length) {
        this.setCurrentIndex(newIndex);
        this.playTrack(newIndex);
      }
    },
    nextTrack() {
      console.log('playList length', this.playlist.length)
      const newIndex = this.currentTrackIndex + 1;
      console.log('playIndex', newIndex)

      if (newIndex < this.playlist.length) {
        this.setCurrentIndex(newIndex);
        this.playTrack(newIndex);
      }
    },
    onTrackEnded() {
      console.log("Track has ended");

      if (this.playlist.length > 0 && this.currentTrackIndex < this.playlist.length - 1) {
        this.playTrack(this.currentTrackIndex + 1);
      } else {
        this.setIsPlaying(false);
      }
    },
    playTrack(index) {
      const track = this.playlist[index];
      if (track) {
        this.$store.commit('SET_AUDIO_PATH', track.audio);
        this.$store.commit('SET_CHECK_AUDIO_PATH', track.audio);
        this.$store.commit('SET_AUDIO_IMAGE', track.image);
        this.$store.commit('SET_AUDIO_TITLE', track.title);
        this.$store.commit('SET_AUDIO_CREATOR', track.creator);
        this.$store.commit('SET_SHOW_PLAYER', true);
        this.$store.commit('SET_IS_PLAYING', true);
        console.log("audio---->", `${this.baseUrl}${this.audio}`)
        console.log("audio---->", this.audioTitle)
        this.$nextTick(() => {
          if (this.$refs.audioElement) {
            this.$refs.audioElement.src = `${this.baseUrl}${track.audio}`;
            this.$refs.audioElement.play().then(() => {
              console.log("Audio is playing");
            }).catch((error) => {
              console.error("Error playing audio:", error);
            });
          } else {
            console.error("audioPlayer ref is not defined.");
          }
        });
      }
    },
    onTimeUpdate(event) {
      this.currentTime = event.target.currentTime;
      this.duration = event.target.duration;
    },

    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },

    seek(event) {
      const audioElement = this.$refs.audioElement;
      if (audioElement) {
        const seekTime = parseFloat(event.target.value);
        audioElement.currentTime = seekTime;
        this.currentTime = seekTime;
      }
    },

    toggleMute() {
      const audioElement = this.$refs.audioElement;
      if (audioElement) {
        this.isMuted = !this.isMuted;
        audioElement.muted = this.isMuted;
      }
    },

    adjustVolume(event) {
      const audioElement = this.$refs.audioElement;
      console.log('Adjusting volume:', event.target.value); // Log volume value
      if (audioElement) {
        this.volume = parseFloat(event.target.value);
        audioElement.volume = this.volume;
        this.setVolume(this.volume); // Commit volume change to Vuex
      }
    },

    closePlayer() {
      this.pauseAudio();
      this.currentTime = 0;
      this.setShowPlayer(false); // Commit showPlayer action to Vuex
      this.$emit('closePlayer');
    }
  }
};
</script>